export type TOverflowValue = "auto" | "hidden" | "scroll" | "visible"

export const useBodyOverflowManager = () => {
  const setOverflow = (overflowValue: TOverflowValue) => {
    document.body.style.overflow = overflowValue
  }

  const getBodyOverflow = () => document.body.style.overflow as TOverflowValue

  const overflowAuto = () => {
    setOverflow("auto")
  }

  const overflowHidden = () => {
    setOverflow("hidden")
  }

  const overflowScroll = () => {
    setOverflow("scroll")
  }

  const overflowVisible = () => {
    setOverflow("visible")
  }

  return {
    getBodyOverflow,
    overflowAuto,
    overflowHidden,
    overflowScroll,
    overflowVisible
  }
}
